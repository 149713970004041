/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    strong: "strong",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "accounts"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Important Account for Developer")), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "developer account", "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developers.hubspot.com/docs/api/creating-test-accounts?_ga=2.221701912.1666960614.1652703028-1851930813.1642977339"
  }, "test account (full access for 90 days)")), "\n"), "\n"), "\n"), "\n", React.createElement(_components.h2, null, "Reference"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://developers.hubspot.com/docs/api/account-types?_ga=2.252168841.1666960614.1652703028-1851930813.1642977339"
  }, "Account Types")), "\n"), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://github.com/HubSpot/hubspot-academy-tutorials/tree/master/getting-started-with-hubspot-apis"
  }, "HubSpot API snippets")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
